import { pageData } from '@utils/subpages-data/nieruchomosci';
import React from 'react';

import Subpage from './_subpage';

const RealEstate = () => {
  return <Subpage data={pageData} />;
};

export default RealEstate;
